import MDinput from '@/components/MDinput';
import { validatorRequire } from '@/utils/validators';
import Switch from '@/components/DSE/Switch';

export function getFormDefinition(vue) {
  return {
    name: {
      type: MDinput,
      defaultValue: '',
      props: {
        maxlength: 64,
        required: true
      },
      rules: [{ validator: validatorRequire }]
    },
    useShiftBaseYN: {
      type: Switch,
      defaultValue: true,
      props: {
        caption: 'common.useShiftBaseYN',
        activeText: 'common.yes',
        inactiveText: 'common.no'
      }
    }
  };
}

export default getFormDefinition;

export function createItemFromFormData(vue, form) {
  return form;
}

export function createFormDataFromItem(vue, item) {
  return {
    ...item
  };
}
